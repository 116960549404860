@import "theme-variables/variables";

$theme-colors: (
    "primary": $base-font-color ,
    "secondary": $cl_white

);

$navbar-nav-link-padding-x:  0;

$navbar-light-color:               $navbar-color;
$navbar-light-hover-color:         $navbar-color;
$navbar-light-active-color:        $navbar-color;
$navbar-light-disabled-color:      $navbar-color;

$border-radius:               0;
$border-radius-lg:            0;
$border-radius-sm:            0;


$input-height:                48px;
$input-placeholder-color:     $input-placeholder;
$input-border-color:          $input-border;
$input-border-radius:         0;
$input-box-shadow:            none;
$input-focus-box-shadow:      none;
 //$input-focus-border-color

/*
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
);


*/


/* import only the necessary Bootstrap files */
@import "node_modules/bootstrap/scss/functions"; 
@import "node_modules/bootstrap/scss/variables";
/* --- begin customization ---- */

/* increase the width of the grid lg and xl breakpoints */
$grid-breakpoints: (
	xs: 0,
	sm: 576px,
	md: 768px,
	lg: 1024px,
	xl: 1366px
);
/* increase container width for the wider lg and xl breakpoints */
$container-max-widths: (
  lg: 1050px,
  xl: 1350px
);
/* --- end customization ------ */
/* finally, import Bootstrap to set the changes! */


@import "node_modules/bootstrap/scss/bootstrap";


//@import "plugins/animate";
@import "plugins/swipebox";
@import "plugins/lightslider";

/**
Site main file
*/
/*theme sizes / colors/ fonts */

@import "theme-variables/mixin";


@import "modules/common",
		"modules/header",
    "modules/footer",
    "modules/images",
   "modules/blocks",
   "modules/forms";

