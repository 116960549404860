.blck-bg{
	background-size: cover; background-repeat: no-repeat; background-position: 50% 50%;
	&.spacetop-1-bottom-1{
		padding: 6% 0;	
	}
	&.spacetop-1-bottom-1{
		padding: 6% 0;	
	}
	&.spacetop-2-bottom-1{
		padding: 14% 0 6%;	
	}

	&.blck-bg-snow{
		background-color: $bg-snow;
		padding: 4% 0;	
	}
}
.bg-snow{
	background-color: $bg-snow;
}


.bg-colorbox{
	padding: 25px 30px; position: relative; z-index: 10;
	&.bg-black-op{
		color: $cl_white;
		&.op75{
			background-color: rgba(0,0,0,.75);
		}
		&.op50{
			background-color: rgba(0,0,0,.5);
		}
	}
	@include M_Large_Up{
		padding: 55px 70px;
	}
}

.img-item {
	.img-ratio{ margin-bottom: 20px; }
	h6{ margin-bottom: 10px; }
}


.blck-title{
	margin-bottom: 20px;
}

.blck{
	margin-bottom:35px;
	
	&.blck-contact{
		padding:40px 0;
		background-color: $bg_color_base;
		color:$cl_white;
		:not(.btn){
			color: inherit;
		}
		
	}
	
	.img-ratio{
		//margin-bottom: 15px;
	}
	.embed-responsive{
		margin-bottom: 15px;
	}


	
	@include M_Large_Up{
		margin-bottom:40px;
		
		.embed-responsive{
			margin-bottom: 30px;
		}
		.blck-title{
			margin-bottom: 40px;
		}


		
	}
	@include M_ExtraLarge_Up{
		margin-bottom:70px;
		
		.embed-responsive{
			margin-bottom: 40px;
		}

		
	}


}


.grid-item,
.hovered-item{
	position: relative; overflow: hidden; margin-bottom: 30px;
	.img-ratio .overlay{
		top:-150px; bottom: -150px; 
		padding:30px 40px; text-align: right;
		color: $cl_white;
		-webkit-transition: all 0.3s linear;
		transition: all 0.3s linear;
		a{
			color: inherit;
			@include remove_underline_on_hover ($cl_white,0,1px);
		}
		.d-flex > div { width: 100%; }
	}
	&:hover{
		.img-ratio .overlay{
			background-color: rgba(0,0,0,.5);cursor: pointer; top:0px; bottom: 0px;
		}
	}
}

.filters-button-group{
	a{ 
		display: inline-block; margin-right: 5px; 
		&.is-checked{
			font-weight: bold;
		}
	}
}

.about-elem{
	.text-base{
		position: relative; padding-top: 25px;
		@include M_Medium_Up{
			padding-top: 45px;
		}
	}
}
.hist-line {
	background-color: #000; position: absolute;
	&:after{
		content:""; position:absolute;width: 9px; height: 9px; background-color: #000;border-radius: 5px;
	}

	@include M_Medium_Up{
		width: 350%; height: 1px; top:5px;
		&:after{
			bottom:-4px;
		}
	}
	@include M_Medium_Down{
		width: 1px; height: 550%; top:35px; left: -55px;
		&:after{
			content:""; position:absolute;width: 9px; height: 9px; top:0; left: -4px; border-radius: 5px;
		}
	}
}