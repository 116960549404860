%sn-footer-center{
	@include M_Small_Down{
		text-align: center;
		.text-right{
			text-align: center !important;
		}
	}
}

.footer{
	h6{margin-bottom: 20px;}
	padding:20px 0;
	address,
	p{font-size: 15px;}
	@extend %sn-footer-center;

}


.copyright{
	font-size: 14px; 
	line-height: 17px;
	a{ 
		display:inline-block; margin: 0 5px;
	}
	@extend %sn-footer-center;
}



