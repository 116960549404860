@font-face {
    font-family: 'CircularXX';
    src: url('fonts/CircularXX-Regular.woff2') format('woff2'),
        url('fonts/CircularXX-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}




/*fonts*/

$base-font-size:			17px;
$base-font-color            :#000000;


$bg_color_base              :#0000FF;
$bg-white                   :#fff;
$bg-snow                    :#f6f6f6;




$cl_white                   :#fff;

$input-placeholder          :#fff;
$input-border               :#fff;
$input-placeholder-dark     :#0000FF;
$input-border-dark          :#0000FF;





$title-font-color			:#3e3f42;

$cl-btn-primary				:$base-font-color;//
$cl-navbar-underine			:$cl-btn-primary;

$font-family-base           :'CircularXX', serif;//
$font-family-playfair       :'Playfair Display', serif;//

$body-color					:#fff;//



$title-bg-color				:#ececed;//
$title-bg-darkcolor			:#d5d5d5;//

$cl-highlite				:#7f865c;//


$link-font-color			:#414141;
$blue-font-color			:#1f4088;

$navbar-color				:#414141;//




$bg-footer					:#1f1f1f;//
$bg-darblue					:#0a2236;	//
$bg-green					:#7f865c;	//
$bg-gray					:#ececed;//







