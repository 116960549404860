/*
*  frontend dev : Farkas Imre
*/


/*font*/
%sn-font-regular{font-family:$font-family-base; font-weight: normal;  }
%sn-font-playfair{font-family:$font-family-playfair; font-weight: normal;  }


%sn-flex-center{
	@include flexbox;
	@include justify-content(center);
	@include align-items(center);
}

html { font-size: 62.5%; height: 100% }
body{ 
	//16
	
	@extend %sn-font-regular;
	font-size:$base-font-size - 2 ;
	line-height: 1.625;
	color:$base-font-color; 
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	height: 100%;
	
	@include M_Large_Up{//75
		font-size:$base-font-size ;
	}

	@include M_ExtraLarge_Up{//83
		//font-size:2.4em;
	}
	@include M_XXL_Up{//100%
		//font-size:2.6em;
	}
}

.wrapper{ 
	min-height: 100%; 
	position:relative; overflow: hidden; 
	background-color: $body-color;
}


h1,
.h1,
h2,
.h2{
	@extend %sn-font-playfair;
	font-size: 2em;//30

	@include M_Medium_Up{
		font-size: 2.235em;//35
	}
}

h3,
.h3,
.blockquote{
	@extend %sn-font-playfair;
	font-size: 1.7em;//
	@include M_Medium_Up{
		font-size: 1.882em;//32
	}
}

h4,
.h4{
	@extend %sn-font-playfair;
	font-size:1.647em;//27
}

h5,
.h5 {
	@extend %sn-font-playfair;
	font-size:1.412em;//24
}
h6,
.h6{
	@extend %sn-font-playfair;
		font-size:1.176em;//20
		line-height: 1.3;
}


a{ 
	color:$base-font-color; 
	-webkit-transition: color 500ms ease-out .2s;
	-moz-transition: color 500ms ease-out .2s;
	-o-transition: color 500ms ease-out .2s;
	transition: color 500ms ease-out .2s;
	&:hover{
		color:$base-font-color; 
	}
}

.img-full{
	width: 100%;
}



.mb-base{margin-bottom: 25px;}
.mb-base2x{margin-bottom: 35px;}

@include M_Large_Up{
	.row-0{
		margin-right: -15px;
		margin-left: -15px;
		> div{
			padding-left:0px;
			padding-right: 0px;
		}
	}
}

.row-custom{
	margin-right: -10px;
	margin-left: -10px;
	> div{
		padding-left:10px;
		padding-right: 10px;
	}
}



@include M_Large_Up{
	.mb-base{margin-bottom:35px;}
	.mb-base2x{margin-bottom:45px;}

	.row-custom{
		margin-right: -15px;
		margin-left: -15px;
		> div{
			padding-left:15px;
			padding-right: 15px;
		}
	}
}
@include M_ExtraLarge_Up{
	.mb-base{margin-bottom:55px;}
	.mb-base2x{margin-bottom:80px;}

	.row-custom{
		margin-right: -25px;
		margin-left: -25px;
		> div{
			padding-left:25px;
			padding-right: 25px;
		}
	}
}

@include M_Medium_Up{
	.w-110p{ width: 110%; }
	.w-120p{ width: 120%; }
	.w-130p{ width: 130%; }

	.w-140p{ width: 140%; }
	.w-170p{ width: 170%; }
	.w-190p{ width: 190%; }
	.w-200p{ width: 200%; }
	.bg-colorbox{
		padding: 40px 0;
	}
}
@include M_Large_Up{
	.bg-colorbox{
		padding: 65px 0;
	}
}
@include M_Medium_Down{
	.w-190p,
	.w-140p,
	.w-130p,
	.w-120p,
	.w-110p{ margin-bottom: -50px !important;}
}


.pt-5p{padding-top: 5%;}
.pb-5p{padding-bottom: 5%;}
.mt-5p{margin-top: 5%;}
@include M_Medium_Up{
	.ml-10p{margin-left: 10%;}
}
.vertical-middle{
	@extend %sn-flex-center;
	min-height: 100%;
}





.z-index-10{ position: relative; z-index: 1000; }

.text-base{
	.bg-black-op{
		a {
			color: $cl_white;
			@include remove_underline_on_hover ($cl_white,0,1px);
		}
	}

	h1{ margin-bottom: 30px; }
	h2{ margin-bottom: 15px; }
	h3{ margin-bottom: 25px;}
	h4{ margin-bottom: 20px;}
	h5,
	h6 {margin-bottom: 20px;}
	p{
		margin-bottom:15px;
		a:not(.btn){
			@include remove_underline_on_hover ($base-font-color,0,1px);
		}
	}
	ol{
		margin:0 0 30px ;
		padding:0 0 0 15px;
		&.list-alpha{
			list-style-type:lower-alpha;
			padding:0 0 0 30px;
			> li{
				margin-bottom: 15px;
			}
		}
		ul{
			list-style-type: initial;	

		}
	}
	
	@include M_Large_Up{
		h1,.h1{ margin-bottom: 35px; }
		h2,.h2{ margin-bottom: 20px; }
		h5,.h5 {margin-bottom: 20px;}
		p{
			margin-bottom:20px;
			+ h2{
				margin-top:40px;
			}
		}
	}
	
	p:last-child{
		margin-bottom: 0;
	}
	.text-lg{
		 font-size: 1.176485em;
	}

	.list-simple{
		list-style: none;
		margin: 0; padding: 0;
		li span{ padding-right: 15px; }
	}

	&.text-lnk-lg{
		 a{font-size: 1.14286em;
		 	text-decoration: none;
		 
		 	@include remove_underline_on_hover ($base-font-color,0,1px);
		 }
	}
	
	&.text-lnk-xl{
		 a{
		 	font-size: 1.285717em;
		 	text-decoration: none;
		 	@include remove_underline_on_hover ($base-font-color,0,1px);
		 }
	}


	dl{
		display: flex;
		flex-wrap: wrap;
		dt,dd{ display: inline-block; border: 1px solid red; }

		dt {clear: left; }
	}
}

.equal{
	.h-100{ 
		@include flexbox;
		@include align-items(center);  
	}
}

.lnk-underline{
	color:$base-font-color;
	text-decoration: none !important;
	@include remove_underline_on_hover ($base-font-color,0,1px)
}




.filter-button-group{
	float: right;
	button{
		border: 0; padding: 0; background-color: transparent; margin:0 5px; outline: none;
		&.lnk-underline	{
			&.is-checked{
				//@include remove_underline_on_hover ($base-font-color,1,2px)
			}
		}
	}
}

.sep-line{ height: 1px; background-color: #979797;opacity: .25; margin: 50px 0 50px;}



.table{
	color:$base-font-color;
	thead th,
	th,td{ 
		border-width: 0 0 1px 0; border-color: transparent transparent #fff  ; padding: .5rem; cursor: pointer;
		&.sorttable_sorted_reverse,
		&.sorttable_sorted{
			border-bottom-color: #000;
		}
	}
	&.table-hover tbody tr{
		&:hover{
			td{
				background-color: #fff; color:$base-font-color; 
			}
			&:after{
			 	background-color: #000;
			}
		}
		&:after{
			content: ""; margin-top: 32px;  position:absolute; left:-50%; height: 1px;  width: 5000px; display: block; background-color: transparent; 
		}
	}
}




.btn{
	font-size: .94em; padding:10px 20px; line-height: 1.4;
}

.breadcrumb{
	background-color: transparent;
	a{
		@include remove_underline_on_hover ($base-font-color,0,1px)
	}
}

.title-no{
	span{ border:1px solid rgba(0,0,0,.14); margin-bottom: 15px; padding:5px 13px 10px; font-size: 100%; }
}

.prev-next{
	 font-size: 1em; line-height: 1.8;
}
.img-brand{ display: inline-block; padding: 10px 15px; }