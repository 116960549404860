.frm{
	padding-top: 25px;
	label{
		color: $base-font-color;
		padding-bottom: 7px;
	}
	
	.form-group{margin-bottom: 15px;}	

	textarea.form-control{
		height: auto; resize: none;
	}
	
	select{
		-webkit-appearance: none;
		 appearance: none;
		 &:-ms-expand {
		  	display: none;
		}
	}
	
	.form-control{
		font-size: 1em; padding: 5px; margin-bottom: 15px; border: 2px solid $input-border; height: 50px; background-color: transparent;
	} 
	
	.btn{
		 font-size: 1.14286em; line-height: 1.2; margin-top: 15px;  padding: 15px 30px 12px; min-width: 150px;	 color: $base-font-color;
		 &:hover{
		 	color: $base-font-color;
		 };
	}

	&.frm-light{
		.form-control{
			 border: 2px solid $input-border-dark; 
		}
		.btn{
			color: $cl_white;
		}

	}

	@include M_Medium_Up{
		&.frm-subscribe{
			width: 70%;
		}
	}	
}

.custom-checkbox {
	padding-left: 30px; margin-right: 10px;
	input{ width: 25px; height: 25px; }
	.custom-control-label{
		 &:before{
		 	left:-30px ;width: 20px; height: 20px; border-radius: 2px; border-color: #e2e1e1;
		}
		&:after{
			  width: 20px; height: 20px; left:-30px;
		}
	}
}

select.form-select{
	height: 42px; padding: 5px 15px 3px; border-radius: 0; border:1px solid #afafaf;
} 


